import React from 'react'
import './CourseDetail.css'
import { Link } from 'react-router-dom';

const courseDetailDevops = () => {
  return (
    <>
     <div className='container py-5'>
        <div className='row'>
        <div className='col-12  text-center border1 py-md-3 px-3 py-2 p-0 bg-brand font '><Link to="/courses"><span className='text-white'>SYLLABUS</span></Link></div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>LINUX BASICS AND ADMINISTRATION</div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>LINUX OS INSTALLATION </li>
              <li>NETWORK CONFIGURATION  </li>
              <li>FILE STRUCTURE OF LINUX </li>
              <li>SOFTWARE INSTALLATION ( YUM and RPM)</li>
              <li>TEXT EDITOR ( NANO , VI , VIM, ETC )  </li>
              <li>CAT , CP, MKDIR ,TOUCH. ZCAT </li>
              <li>HEAD , TAIL , GREP  </li>
              <li> RSYNC , SCP</li>
              <li> LINUX SERVER INSTALLATION</li>
              <li>PROCESS MANAGEMENT </li>
              <li>LOG MANAGEMENT </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>DEVOPS TOOLS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>WHAT IS DEVOPS    </li>
              <li>HISTORY OF DEVOPS   </li>
              <li>DEVOPS ENVIRONMENT LAB SETUP </li>
              <li>VERSION CONTROL WITH GIT </li>
              <li>CONTINUOUS INTEGRATION & DEPLOYMENT JENKINS </li>
              <li>CONTAINERIZATION AND ORCHESTRATION </li>
              <li>DOCKER & KUBERNETIS    </li>
              <li>INFRASTRUCTURE AS CODE (IaC) - TERAFOM </li>
              <li>AUTOMATION WITH ANSIBLE  </li>
              <li>MONITORING AND LOGGIN WITH GRAFANA </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>CLOUDS AWS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li> I AM   </li>
              <li>EC2   </li>
              <li>ELASTIC IPS   </li>
              <li>CODE COMMIT </li>
              <li>SECURITY GROUPS</li>
              <li>LOAD BALANCERS    </li>
              <li>SNAPSHOT </li>
              <li>S3 BUCKET</li>
              <li>CLOUD FRONT </li>
              <li>SOME BASIC KNOWLADGE ABOUT OTHER CLOUD INFRA </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>TOOLS  </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>VIRTUAL BOX  </li>
              <li>PUTTY </li>
              <li>TOP,  HTOP.  </li>
              <li>SITE24X7 MONITORING </li>
              
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>PROJECTS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>HOW TO DEPLOY WORDPRESS WEBSITES ON K8S CLUSTER</li>
              <li>HOW TO DEPLOY APACHE AND NGINX APPLICATIONS ON DOCKER </li>
              <li>HOW TO DEPLOY JAVA APPLICATION ON K8S</li>
              <li>HOW TO AUTOMATE DEPLOYMENT WITH CI/CD </li>
              <li>HOW TO SETUP GITLAB SERVER</li>
              <li>HOW TO IN HOUSE GIT SERVER WITH HTTP</li>
              <li>HOW TO SETUP JENKINS SERVER</li>
              <li>BASIC OF BASH SCRIPTS  USED OF FOR , IF, ELSE AND VARIABLES</li>
              <li>HOW TO SETUP MYSQL SERVER OR POSTGRES DATABASE on DOCKER</li>
              <li>HOW TO K8S CLUSTER WITH 3 MASTER AND 2 WORKER NODE</li>
            </ul>
          </div>
        </div>
        
      </div>
    
    
    
    </>
  )
}

export default courseDetailDevops