import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Feedback.css'; 

import student from '../assert/student.png'
import { TypeAnimation } from 'react-type-animation';
import flag from "../assert/flag.png"
import india from "../assert/india.png"


const feedbackData = [
  {
    id: 1,
    image: flag,
    logo: student,
    name: ' Samuel Arthur',
    feedback: '“If am able to see afar of my career and be productive in a real world environment then I could 💯% say nscloudacademy was my bedrock and continues to be….“I recommend their training to everyone.',
 
  },
  {
    id: 2,
    image: flag,
    logo: student,
    name: 'Michael Beick Baffour',
    feedback: '“NS Cloud Academy have very thorough and patient instructor. Really they knows their stuff and is always available to answers questions related to the topic.',
   
  
  },
  {
    id: 3,
    image: flag,
    logo: student,
    name: 'Albert',
    feedback: `“I had little or no knowledge about computers but NS Cloud Academy took me through an intensive but thorough Linux System Administrator class, and he made it seem so easy for me. Even with the odd time difference, he was there every time I needed help. He goes above and beyond even with things that were not in the class curriculum."  `,
  
  
  },
  {
    id: 4,
    image: flag,
    logo: student,
    name: ' Derrick',
    feedback: 'The Linux Administrator course at NS Cloud Academy exceeded my expectations. The hands-on labs, detailed content, and expert guidance made learning Linux administration a breeze. Highly recommend this course.',
   
   
  },
  {
    id: 5,
    image: flag,
    logo: student,
    name: 'Kweku Gyan',
    feedback: "NS Cloud Academy's Linux Administrator course is exceptional. The course content is in-depth, the instructors are highly knowledgeable, and the hands-on labs provide practical experience. This program equips students with the skills needed for a successful career in Linux administration. I highly recommend it to aspiring sysadmins",
   
  },
  {
    id: 6,
    image: india,
    logo: student,
    name: 'Ritesh Singh',
    feedback: 'NS Cloud Academy provides excellent Linux Administrator courses. The content is comprehensive, instructors are knowledgeable, specially Nirmal Sir, and the interactive learning platform is user-friendly. Highly recommended for anyone pursuing for Linux Administrator expertise.',
   
  },
 
];




const FeedbackSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, 
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 

  return (
    <div className="container-fluid" style={{ backgroundColor: '#f4f4f5', overflowX: "hidden" }}>
      <div className="sec-title-style1 float-left text-center">
        <TypeAnimation
          sequence={[
            'Student Feedback',
            3000,
            ' Feedback',
            3000,
          ]}
          wrapper="span"
          className="title"
          speed={50}
          style={{ fontSize: '2em', display: 'inline-block' }}
          repeat={Infinity}
        />
        <div className="text">
          <div className="decor-left">
            <span></span>
          </div>
          <p>student feedback</p>
          <div className="decor-right">
            <span></span>
          </div>
        </div>
      </div>
      <Slider {...settings}>
        {feedbackData.map((student) => (
          <div key={student.id} style={{ margin: '0 2px' }}>
            <div className="container py-5">
              <div className="row g-2">
                <div className="col-12">
                  <div className="card border-0 shadow bg-light card1" >
                    <div className="card-body">
                      <div className="icon-wrapper">
                        <img src={student.logo} alt={student.name} className="img-fluid" />
                      </div>
                      <h5 className="card-title text-center d-flex justify-content-center align-items-center">{student.name} <span className='ms-3'>  <img src={student.image} alt="country_flag" style={{objectFit:"cover",maxHeight:"25px",maxWidth:"25px"}} className="img-fluid" /></span></h5>
                      <p className="card-text">{student.feedback}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FeedbackSlider;
