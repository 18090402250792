import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light " style={{ backgroundColor: "#d1d5db",overflowX:"hidden" }}>
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <span style={{
            // fontSize: "22px",
            fontWeight: "bold",
            color: "#8b2424"
          }}>NS CLOUD ACADEMY</span>
        </NavLink>
        <button
          className={`navbar-toggler ${isNavOpen ? '' : 'collapsed'}`}
          type="button"
          onClick={handleNavToggle}
          aria-expanded={isNavOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/about">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/photo">Gallery</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/batch">Services</NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/courses">Courses</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
