import React from 'react';
import './cardes.css';
import { TypeAnimation } from 'react-type-animation';
import about1 from "../assert/about1.jpg";
import about2 from "../assert/about2.jpg";

const containerStyle = {
  height: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: '"Raleway", Arial, sans-serif',
  backgroundColor: '#f4f4f5',
};

const cardData = [
  {
    imgSrc: about1,
    title: 'Technical Experience',
    text: "We have more than 15 years of experience in the world of technology. We are well-versed in a variety of Linux operating systems, databases, and cloud environments. We provide you real-world experience that makes students very confident. We use this expertise to help our students build their careers in IT.",
  },
  {
    imgSrc: about2,
    title: 'Satisfaction Guaranteed',
    text: "The world of technology can be fast-paced and scary. That's why our goal is to provide real-world experience to our students as per company's needs. We pride ourselves on providing professional courses. We guarantee you will be satisfied with our teaching techniques. We also provide lifetime support until students feel confident.",
  },
];

function About() {
  return (
    <>
      <div className='container-fluid'>
        <div className='row'>
          <div className="sec-title-style1 float-left text-center" style={{ backgroundColor: '#f4f4f5' }}>
            <TypeAnimation
              sequence={[
                'About US',
                3000,
                ' About',
                3000,
              ]}
              wrapper="span"
              className="title"
              speed={50}
              style={{ fontSize: '2em', display: 'inline-block' }}
              repeat={Infinity}
            />
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p>About us</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
          </div>

          {cardData.map((card, index) => (
            <div key={index} className='col' sm={12} md={6} lg={6} style={containerStyle}>
              <div className="cardes">
                <div className="imgboxes">
                  <img
                    src={card.imgSrc}
                    alt="Smiling person"
                    style={{
                      width: '100%',
                      borderRadius: '10px',
                      boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.5)',
                    }}
                  />
                  <h3 className="cont">{card.title}</h3>
                </div>

                <div className="contentes">
                  <p className="text-muted">
                    {card.text}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default About;
