import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

import slider from '../assert/slider.jpeg';
import slider1 from '../assert/slider1.jpg';



function MyCarousel() {
  const carouselData = [
  
    {
      id: 2,
      imageSrc: slider,
      title: 'PROFESSIONAL ONLINE CLASSES',
      description: 'Unlock your potential for IT with our customized real-world solution',
    },
    {
      id: 3,
      imageSrc: slider1,
      title: 'PROFESSIONAL ONLINE CLASSES',
      description: 'Unlock your potential for IT with our customized real-world solution',
    },
  ];

  const captionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    padding: '10px',
    textAlign: 'center',
  };
  

  return (
  <div className='conatiner-fluid' style={{overflowX:"hidden",overflowY:"hidden"}}>
    <div className='row'>
      <div className='col '>
      {/* interval={null} */}
      <Carousel interval={null} controls={false} >
      {carouselData.map((item) => (
        <Carousel.Item key={item.id} className='text-center'>
          <img
            className="d-block w-100"
            src={item.imageSrc}
            alt={item.title}
            style={{ maxHeight: '600px', objectFit: 'cover' }}
          />
          <div style={captionStyle} >
            <h1 className='fs-1 fw-bold' >{item.title}</h1>
            <h4 className='fs-5 fw-200'>{item.description}</h4>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
      </div>
    </div>
  </div>
  );
}

export default MyCarousel;
