import React from 'react';
import './Courses.css';
import logo1 from '../assert/logo1.svg';
import logo2 from '../assert/logo2.svg';
import logo3 from '../assert/logo3.svg';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';


const coursesData = [
  {
    id: 1,
    logo: logo1,
    title: 'Linux System Administrator',
    description:
      'Linux OS Installation, Network Configuration, File Structure of Linux',
    to:"/linux"
  },
  {
    id: 2,
    logo: logo2,
    title: 'DevOps Engineer',
    description:
      'GIT, Jenkins, Kubernetes, Docker, Continuous Integration & Deployment ',
      to:"/devops"
  },
  {
    id: 3,
    logo: logo3,
    title: 'Cloud Engineer',
    description:
      'Amazon Web Services (AWS), Oracle Cloud Infrastructure (OCI) ',
      to:"/cloud"
  },
];

function Courses() {
  return (
    <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="sec-title-style1 float-left text-center">
         
             <TypeAnimation
      sequence={[
    'Our Online Courses',
        3000,
    ' Online Courses',
        3000,
    'Our Online Courses',
        3000,

       
       
      ]}
      wrapper="span"
      className="title"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p>Online Courses</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mt-md-4 row-cols-1 row-cols-sm-2 row-cols-md-3 justify-content-center">
          {coursesData.map((course) => (
            <div key={course.id} className="col">
              <div className="service-card">
                <div className="icon-wrapper">
                  <img src={course.logo} alt={course.title} className="img-fluid" />
                </div>
                <h3>{course.title}</h3>
                <p className='text-muted'>{course.description}</p>
                <Link to={course.to}>
 <button className="btn btn-outline-danger " type="button" style={{ backgroundColor: "rgb(255, 23, 131)", color: "white", width: "100%" }}>
    Get more Information
  </button>
</Link>

              </div>

            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Courses;
