import React from 'react'
import './CourseDetail.css'
import { Link } from 'react-router-dom';

const courseDetailCloud = () => {
  return (
   <>
    <div className='container py-5'>
        <div className='row'>
        <div className='col-12  text-center border1 py-md-3 px-3 py-2 p-0 bg-brand font '><Link to="/courses"><span className='text-white'>SYLLABUS</span></Link></div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>AMAZON WEB SERVICES (AWS) </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>INTRODUCTION OF AWS  </li>
              <li>AWS IDEBTITY AND ACCESS MANAGEMENT (IAM)   </li>
              <li>COMPUTE SERVICES </li>
              <li>STORAGE SERVICES </li>
              <li>DATABASE SERVICES </li>
              <li>NETWORKING AND CONTENT DELEVERY (CDN) </li>
              <li>AUTO SCALING</li>
              <li>LOAD BALANCERS    </li>
              <li>COST OPTIMISATION </li>
              <li>SECURITY AND MONITORING </li>
              <li>S3 BUCKET</li>
              <li>CLOUD FRONT </li>
              <li>AND MUCH MORE TO TURN NORMAL JOB IN TO AWS. </li>
              <li>DEPLOY A WORDPRESS WEBSITE WITH MYSQL DB FROM SCRATCH ON AWS  </li>
              <li>HOW TO DEPLOY STATIC WEBSITE ON AWS S3 </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>ORACLE CLOUD INFRASTRUCTURE (OCI) </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>OCI INTRODUCTION  </li>
              <li>IDENTITY AND ACCESS MANAGEMENT </li>
              <li>NETWORKING - VIRTUAL CLOUD NETWORK </li>
              <li>NETWORKING - LOAD BALANCER </li>
              <li>NETWORKING - DNS MANAGEMENT </li>
              <li>COMPUTE SERVICES     </li>
              <li>OBJECT STORAGE </li>
              <li>BLOCK STORAGE </li>
              <li>FILE STORATE </li>
              <li>SECURITY PLATFORM </li>
              <li>OBSERVABILITY AND MANAGEMENT - MONITORING </li>
              <li>OBSERVABILITY AND MANAGEMENT - LOGGING </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>TOOLS  </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>VIRTUAL BOX  </li>
              <li>PUTTY </li>
              <li>TOP,  HTOP.  </li>
              <li>SITE24X7 MONITORING </li>
              
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>PROJECTS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>HOW TO DEPLOY COMPLETE WORDPRESS WEBSITES ON OCI BEHIND LB </li>
              <li>HOW TO DEPLOY K8S Cluster ON OCI </li>
              <li>HOW TO SETUP PRIVATE AND PUBLIC LB  </li>
              <li>HOW TO CREATE CUSTOME IMAGES AND UPLOAD ON OCI</li>
              <li>HOW TO MIGRATE KVM,HYPERV,VMWARE MACHIN TO OCI </li>
              <li>HOW TO DEPLOY APPLICATIONS WITH AUTO SCALING </li>
              </ul>
          </div>
        </div>
        
      </div>
   
   
   
   </>
  )
}

export default courseDetailCloud