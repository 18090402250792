import React from 'react'


const Footers = () => {
  return (
   <>
 
 <div className="container-fluid   p-3" style={{backgroundColor:"#d1d5db"}}>
 
  <div className="row">
    <div className="col text-center text-white">
   <h6 style={{fontSize:"20px"}}> Copyright © 2024 NS Cloud Academy - All Rights Reserved.</h6>
    </div>
    {/* <div className="col-4 text-center text-white">
      Variable width content
    </div> */}
   
  </div>
</div>

   </>
  )
}

export default Footers