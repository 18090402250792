import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import devops from '../assert/devops.jpg'; 
import aws1 from '../assert/aws1.jpg';
import aws2 from '../assert/aws2.jpg'; 
import './Photo.css'; 
import { TypeAnimation } from 'react-type-animation';

export default function Photo() {
  const images = [
    {
      original: devops,
      thumbnail: devops,
    },
    {
      original: aws2,
      thumbnail: aws2,
    },
    {
      original: aws1,
      thumbnail: aws1,
    },
  ];

  return (
    <div className="container-fluid " style={{ backgroundColor: "#f4f4f5", overflowX: "hidden" }}>
      <div className="row">
        <div className="col my-2">
          <div className="sec-title-style1 float-left text-center">
        
            <TypeAnimation
      sequence={[
    'Our Gallery',
        3000,
    ' Gallery',
        3000,
      ]}
      wrapper="span"
      className="title"
      speed={50}
      style={{ fontSize: '2em', display: 'inline-block' }}
      repeat={Infinity}
    />
            <div className="text">
              <div className="decor-left">
                <span></span>
              </div>
              <p className="mb-3">gallery</p>
              <div className="decor-right">
                <span></span>
              </div>
            </div>
            <ImageGallery
              items={images}
              showNav={false}
              showPlayButton={false}
              showBullets={false}
              autoPlay={true}
              slideInterval={3000}
              showFullscreenButton={false}
              additionalClass="responsive-gallery" // Add a custom CSS class for responsiveness
            />
          </div>
        </div>
      </div>
    </div>
  );
}
