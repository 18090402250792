import React from 'react';
// import { Col, Container, Row,Card } from 'react-bootstrap';
// import contact from '../assert/contact.jpg';
// import '../App.css';
// import { TypeAnimation } from 'react-type-animation';

// import { Link } from 'react-router-dom';

const Batch = () => {
  return (
    // <Container fluid className='my-3' style={{ backgroundColor:"#f4f4f5"}}>
    //   <Row>
    //   <div className="sec-title-style1 float-left text-center " style={{ backgroundColor: '#f4f4f5'}}>
    //       {/* <div className="title">Services</div> */}
    //       <TypeAnimation
    //   sequence={[
    // 'Our Services',
    //     3000,
    // ' Services',
    //     3000,
    //   ]}
    //   wrapper="span"
    //   className="title"
    //   speed={50}
    //   style={{ fontSize: '2em', display: 'inline-block' }}
    //   repeat={Infinity}
    // />
    //       <div className="text">
    //         <div className="decor-left">
    //           <span></span>
    //         </div>
    //         <p>Services</p>
    //         <div className="decor-right">
    //           <span></span>
    //         </div>
    //       </div>
    //     </div>
    //     <Col xs={12} md={6} className='d-flex flex-column align-items-center justify-content-center mb-3 '>
          
    //       <h1><span style={{color:"#FFA500"}}>Better yet, see us in person!</span></h1>
    //       <p className="text-center">We love our students, so feel free to visit during normal business hours.</p>
    //       <Card>
    //         <Card.Body>  <Link
    //   to="https://wa.me/919958513365" 
    //   rel="noopener"
    //   data-ux-btn="external"
    //   data-ux="ButtonExternal"
    //   className="x-el x-el-a c1-bb c1-c c1-5p c1-bc c1-3 c1-6a c1-61 c1-y c1-2i c1-1x c1-1y c1-1z c1-20 c1-bd c1-be c1-bf c1-bg c1-bh c1-bi c1-1w c1-60 c1-1b c1-1a c1-1t c1-1c c1-1u c1-n c1-3j c1-62 c1-63 c1-4u c1-64 c1-65 c1-1 c1-2 c1-bj c1-bk c1-bl c1-bm c1-bn c1-bo c1-68 c1-bp c1-bq c1-br c1-bs"
    // >
    //   <span data-ux="Element" className="x-el x-el-span c1-c c1-bc c1-n c1-bt c1-12 c1-1r c1-1s">
    //     <span data-ux="Element" className="x-el x-el-span c1-bu c1-bv c1-2h c1-a8 c1-2k c1-bw c1-bx c1-a9 c1-by c1-bc c1-c">
    //       <svg viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px" data-ux="Icon" className="x-el x-el-svg c1-3n c1-1g c1-3q c1-bc c1-c">
    //         <svg viewBox="0 0 496 497">
    //           <defs>
    //             <linearGradient id="a" x1="247.32" x2="247.32" y1="446.09" y2="39.9" gradientUnits="userSpaceOnUse">
    //               <stop offset="0" stopColor="#20b038" />
    //               <stop offset="1" stopColor="#60d66a" />
    //             </linearGradient>
    //             <linearGradient id="b" x1="247.32" x2="247.32" y1="453.37" y2="32.61" gradientUnits="userSpaceOnUse">
    //               <stop offset="0" stopColor="#f9f9f9" />
    //               <stop offset="1" stopColor="#fff" />
    //             </linearGradient>
    //           </defs>
    //           <path
    //             d="M37.88 453.37l29.59-108A208 208 0 0 1 39.63 241.1c0-115 93.6-208.49 208.56-208.49a208.57 208.57 0 0 1 208.57 208.66c-.05 115-93.62 208.49-208.57 208.49h-.08a208.41 208.41 0 0 1-99.67-25.38zm115.68-66.73l6.34 3.75a173.18 173.18 0 0 0 88.23 24.16h.06c95.55 0 173.31-77.75 173.35-173.3A173.34 173.34 0 0 0 248.26 67.83c-95.62 0-173.38 77.73-173.42 173.28a172.94 172.94 0 0 0 26.5 92.23l4.13 6.55L88 403.84z"
    //           ></path>
    //           <path fill="url(#a)" d="M45.13 446.09l28.57-104.3a200.82 200.82 0 0 1-26.88-100.62c0-111 90.36-201.27 201.34-201.27A201.35 201.35 0 0 1 449.5 241.32c0 111-90.37 201.28-201.33 201.28h-.09a201.31 201.31 0 0 1-96.21-24.49z"></path>
    //           <path fill="url(#b)" d="M37.88 453.37l29.59-108A208 208 0 0 1 39.63 241.1c0-115 93.6-208.49 208.56-208.49a208.57 208.57 0 0 1 208.57 208.66c-.05 115-93.62 208.49-208.57 208.49h-.08a208.41 208.41 0 0 1-99.67-25.38zm115.68-66.73l6.34 3.75a173.18 173.18 0 0 0 88.23 24.16h.06c95.55 0 173.31-77.75 173.35-173.3A173.34 173.34 0 0 0 248.26 67.83c-95.62 0-173.38 77.73-173.42 173.28a172.94 172.94 0 0 0 26.5 92.23l4.13 6.55L88 403.84z"></path>
    //           <path
    //             fill="#fff"
    //             d="M196.07 153.94c-3.91-8.68-8-8.85-11.73-9-3-.14-6.51-.13-10-.13a19.15 19.15 0 0 0-13.89 6.52c-4.78 5.22-18.24 17.82-18.24 43.46s18.67 50.42 21.28 53.9 36.05 57.77 89 78.66c44 17.36 53 13.91 62.53 13s30.83-12.61 35.18-24.78 4.34-22.59 3-24.77-4.78-3.48-10-6.08-30.83-15.22-35.61-16.95-8.25-2.61-11.73 2.61-13.45 16.94-16.5 20.42-6.08 3.92-11.29 1.31-22-8.11-41.9-25.86c-15.5-13.82-26-30.87-29-36.09s-.32-8 2.29-10.63c2.34-2.34 5.21-6.09 7.82-9.13s3.47-5.21 5.21-8.69.87-6.52-.44-9.13-11.35-28.34-15.98-38.64z"
    //           ></path>
    //         </svg>
    //       </svg>
    //     </span>
    //     <span data-ux="Element" className="x-el x-el-span iconText c1-c c1-bc c1-52 c1-1a c1-bz c1-12 c1-1r c1-1s ms-3">Message us on WhatsApp</span>
    //   </span>
    // </Link></Card.Body>
    //       </Card>
    //       <h5 className='text-center'>NS Cloud Academy</h5>
    //        <h6 style={{fontSize: "calc(1.0rem + .3vw)"}} className='text-center'>Next Batch Will start from <span style={{color:"#FFA500"}}>3rd MAR 6PM IST</span></h6>
        
    //     </Col>
    //     <Col xs={12} md={6} className='text-center mb-3'>
    //       <img src={contact} alt="wall Contact" className="rounded-2" style={{width:"atuo",height:"300px"}}/>
    //     </Col>
    //   </Row>
    // </Container>
    <></>
  );
};

export default Batch;
