import React from 'react';
import './CourseDetail.css'
import { Link } from 'react-router-dom';

const courseDetailLinux = () => {
  return (
    <>
      <div className='container py-5'>
        <div className='row'>
       
         <div className='col-12  text-center border1 py-md-3 px-3 py-2 p-0 bg-brand font '><Link to="/courses"><span className='text-white'>SYLLABUS</span></Link></div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>BASICS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>LINUX  OS INSTALLATION </li>
              <li>NETWORK CONFIGURATION  </li>
              <li>FILE STRUCTURE OF LINUX </li>
              <li>USER AND GROUP MANAGEMENT. </li>
              <li>SOFTWARE INSTALLATION ( YUM - RPM AND SOURCE CODE)</li>
              <li>TEXT EDITOR ( NANO , VI , VIM, ETC )  </li>
              <li>FIND  </li>
              <li>SED  </li>
              <li>TAR , GZIP, UNZIP</li>
              <li>CAT , CP, MKDIR ,TOUCH. ZCAT </li>
              <li>HEAD , TAIL , GREP  </li>
              <li> RSYNC , SCP</li>
              <li>LINUX FILESYSTEM ( FDISK , PARTED ) </li>
              <li>FILE PERMISSION ( SPECIAL PERMISSION ) </li>
              <li>PROCESS MANAGEMENT </li>
              <li>LOG MANAGEMENT </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>SERVERS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>YUM SERVER    </li>
              <li>SSH SERVER   </li>
              <li>SAMBA SERVER and (ADVANCE SAMBA ) </li>
              <li>NFS SERVER </li>
              <li>APACHE (HTTP) (HTTPS) </li>
              <li>LVM     </li>
              <li>SERVER BACKUP </li>
              <li>MYSQL SERVER  MANAGEMENT </li>
              <li>GIT SERVER </li>
              <li>XAMPP or LAMPP SERVERS   </li>
              <li>WILDFLY SERVERS   </li>
              <li>TOMCAT SERVERS   </li>
              <li>JBOSS APPLICATION SERVER   </li>
              <li>NGINX REVERSE PROXY AND LOAD BALANCER </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>CLOUDS AWS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li> I AM   </li>
              <li>EC2   </li>
              <li>ELASTIC IPS   </li>
              <li>CODE COMMIT </li>
              <li>SECURITY GROUPS</li>
              <li>LOAD BALANCERS    </li>
              <li>COST OPTIMISATION </li>
              <li>SNAPSHOT </li>
              <li>S3 BUCKET</li>
              <li>CLOUD FRONT </li>
              <li>AND MUCH MORE TO TURN NORMAL JOB IN TO AWS. </li>
              <li>SOME BASIC KNOWLADGE ABOUT OTHER CLOUD INFRA </li>
              <li>HOW TO DO OS PATCHING </li>
              <li>DEPLOY A WORDPRESS WEBSITE WITH MYSQL DB FROM SCRATCH ON AWS  </li>
              <li>HOW TO DEPLOY STATIC WEBSITE ON AWS S3 </li>
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>TOOLS  </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>VIRTUAL BOX  </li>
              <li>PUTTY </li>
              <li>TOP,  HTOP.  </li>
              <li>SITE24X7 MONITORING </li>
              
            </ul>
          </div>
          <div className='col-12 col-md-6 border justify-content-center align-items-center d-flex border1 py-md-3 px-3 py-2 rowFont'>PROJECTS </div>
          <div className='col-12 col-md-6 border border1 py-md-3 px-3 py-2 font'>
            <ul style={{ listStylePosition: "inside" }}>
              <li>HOW TO DEPLOY COMPLETE WORDPRESS HOSTING WEBSITES ON SERVER</li>
              <li>HOW TO APPLY FREE SSL CERTIFICATE IN APACHE AND NGINX  SERVER </li>
              <li>HOW TO RENEW SSL CERTIFICATE PURCHASED BY HOSTING PROVIDER</li>
              <li>HOW TO SETUP SFTP JAILED ENVIRONMENT   </li>
              <li>HOW TO SETUP NGINX REVERSE PROXY AND LOAD BALANCER</li>
              <li>HOW TO CONFIGURE HTTP OR APACHE SERVER </li>
              <li>HOW TO CREATE GITLAB SERVER</li>
              <li>HOW TO SETUP JENKINS SERVER</li>
              <li>BASIC OF BASH SCRIPTS  USED OF FOR , IF, ELSE AND VARIABLES</li>
              <li>HOW TO SETUP MYSQL SERVER OR POSTGRES DATABASE SERVER</li>
              <li>HOW TO DEPLOY JAVA APPLICATION ON TOMCAT SERVER</li>
              <li>HOW TO DEPLOY APPLICATIONS ON WILDFLY CONTAINER</li>
            </ul>
          </div>
        </div>
        
      </div>
    </>
  )
}

export default courseDetailLinux;
